import { render, staticRenderFns } from "./HackernewsTrending.vue?vue&type=template&id=336e5354&scoped=true"
import script from "./HackernewsTrending.vue?vue&type=script&lang=js"
export * from "./HackernewsTrending.vue?vue&type=script&lang=js"
import style0 from "./HackernewsTrending.vue?vue&type=style&index=0&id=336e5354&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "336e5354",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('336e5354')) {
      api.createRecord('336e5354', component.options)
    } else {
      api.reload('336e5354', component.options)
    }
    module.hot.accept("./HackernewsTrending.vue?vue&type=template&id=336e5354&scoped=true", function () {
      api.rerender('336e5354', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Widgets/HackernewsTrending.vue"
export default component.exports