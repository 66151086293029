var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hackernews-wrapper" }, [
    _vm.trendingPosts
      ? _c(
          "div",
          { staticClass: "posts-wrapper" },
          _vm._l(_vm.trendingPosts, function (trendingPosts, index) {
            return _c("div", { key: index, staticClass: "post-row" }, [
              _c(
                "a",
                {
                  staticClass: "post-top",
                  attrs: { href: trendingPosts.originURL },
                },
                [
                  _c("div", { staticClass: "post-title-wrap" }, [
                    _c("p", { staticClass: "post-title" }, [
                      _vm._v(_vm._s(trendingPosts.title)),
                    ]),
                    _c("p", { staticClass: "post-date" }, [
                      _vm._v(
                        " " + _vm._s(_vm.formatDate(trendingPosts.time)) + " "
                      ),
                    ]),
                    trendingPosts.score
                      ? _c("p", { staticClass: "post-score" }, [
                          _vm._v("score: " + _vm._s(trendingPosts.score)),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }